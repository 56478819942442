import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AddTagFn,
  NgSelectComponent,
} from '@ng-select/ng-select/lib/ng-select.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  filter,
  startWith,
  BehaviorSubject,
  Observable,
} from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { toFirstLetterLower } from '../../helper/stringhelper';
import { ModalService } from '../../service/modal.service';
import { TypeaheadApiService } from '../../store/typeahead/typeahead.api.service';
import { TypeaheadService } from '../../store/typeahead/typeahead.service';
import {
  DataType,
  DropdownStyle,
  InputEnumDataTypeahead,
  InputReferenceDataTypeahead,
  InputTypeahead,
  ListItem,
  ReferencesItem,
} from '../../store/typeahead/types';
import { Entity } from '../../store/view/types';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { debugLog, filterTrue } from '../../pipe/rxjs/operators';
import { ActionButton } from '../modal-content/action-button';

@UntilDestroy()
@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent implements OnInit, OnChanges {
  @Input() dbNameOfName: string = 'name';
  @Input() dbNameOfId: string = 'id';
  @Input() orderBy: string;
  // @Input() orderBy: string;
  //Look like a Dropdown or a DropdownList with an input search
  @Input() dropdownStyle: DropdownStyle = DropdownStyle.DropDownList;
  @Input() domain: string = '';
  @Input() pageSize: number = 10;
  @Input() entity: Entity;
  //Limit values from/to a list of a dropdown
  @Input() limitToList: boolean = false;
  @Input() routeEndpoint: string;
  @Input() allowCustomActions: boolean = false;
  @Input() readonly: boolean = false;

  @Input() referenceEndpoint: string;
  @Input() quickAdd: boolean | AddTagFn = false;

  @Input() valueMember: string = 'id';
  @Input() displayMember: string = 'name';
  private usedMember: string = 'name';

  @Input() data: any[] | null | undefined;

  @Input() dataValueMember: string;

  @Input() dataType: DataType = DataType.ReferenceData;

  @Input() showFirstElement: boolean = false;
  private isNewValue: boolean = false;

  @Input() controlForm: any;
  @Input() placeholder: string = '';

  @ViewChild('select') ngSelect: NgSelectComponent;
  @ViewChild('myselectRef') ngSelectRef: NgSelectComponent;

  protected readonly DropdownStyle = DropdownStyle;
  searchFilter: string;
  filter: string;
  searchInput$ = new BehaviorSubject<string>('');
  termSearchString: string = '';
  allowEdit: boolean = true;

  dataSource$ = new BehaviorSubject<ListItem[] | ReferencesItem[]>([]);
  quickAddResult$: Observable<ListItem>;

  @Input() referenceField: string;
  @Input() referenceFilterParentName: string;
  @Input() referenceFilterParentValue: string;

  private isInitEnded: boolean = false;
  private isFirstTimeInit: boolean = true;

  private oldFilter: string = '';

  constructor(
    private readonly typeaheadService: TypeaheadService,
    private readonly typeaheadApiService: TypeaheadApiService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService
  ) {
    this.quickAddResult$ = this.typeaheadService.quickAdd.value$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.routeEndpoint) {
      // if (this.routeEndpoint === 'Banks') {
      //   console.log(this.routeEndpoint);
      //   console.log(changes);
      //   console.log(changes['referenceFilterParentValue']);
      // }

      if (changes['referenceFilterParentValue']) {
        if (
          !changes['referenceFilterParentValue'].isFirstChange() &&
          changes['referenceFilterParentValue'].previousValue !==
            changes['referenceFilterParentValue'].currentValue
        ) {
          if (this.isInitEnded) {
            // console.log(this.controlForm.value);
            if (this.controlForm.value) {
              this.cleanSearchTerm();
            }
            this.filter = '';
            if (changes['referenceFilterParentValue'].currentValue) {
              this.filter =
                this.referenceFilterParentName +
                '=' +
                changes['referenceFilterParentValue'].currentValue;
            }
            this.searchInput$.next('');
          }
        }
        //Initialisation (reload or normal navigation)
        if (
          !this.isInitEnded &&
          this.referenceFilterParentValue ===
            changes['referenceFilterParentValue'].currentValue
        ) {
          this.isInitEnded = true;
          this.searchInput$.next('');
        }
      }
      if (changes['domain']) {
        //allow dynamic domain to refresh data
        this.searchInput$.next('');
      }
    }

    if (this.referenceEndpoint) {
      // console.log(this.referenceEndpoint);
      // console.log(changes['referenceFilterParentValue']);
      if (changes['referenceFilterParentValue']) {
        if (
          !changes['referenceFilterParentValue'].isFirstChange() &&
          changes['referenceFilterParentValue'].previousValue !==
            changes['referenceFilterParentValue'].currentValue
        ) {
          if (this.isInitEnded) {
            if (this.controlForm.value) {
              this.cleanRefTerm();
            }
            this.isNewValue = true;
            this.callReferenceData();
          }
        }
        //Initialisation (reload or normal navigation)
        if (
          !this.isInitEnded &&
          this.referenceFilterParentValue ===
            changes['referenceFilterParentValue'].currentValue
        ) {
          this.isInitEnded = true;
          this.callReferenceData();
        }
      }
    }

    if (this.dataValueMember) {
      if (changes['data'] && this.data) {
        const result = this.data.map(
          (item) => <ReferencesItem>{ name: item[this.dataValueMember] }
        );
        this.dataSource$.next(result);
        this.valueMember = 'name';
        this.displayMember = 'name';
      }
    }
  }

  ngOnInit(): void {
    this.filter = '';
    if (!this.orderBy) this.orderBy = this.dbNameOfName + ' asc';

    this.searchInput$
      .pipe(
        startWith(''),
        filter((term) => !!this.routeEndpoint && term !== null),
        debounceTime(200),
        tap((term) => (this.termSearchString = term))
        // tap((x) => console.log(this.routeEndpoint)),
        // tap((x) => console.log(x)),
      )
      .subscribe((termSearchString) => {
        this.searchFilter = '';
        if (termSearchString.length > 0) {
          this.searchFilter = this.dbNameOfName + '=*' + termSearchString;
        }

        let filter: string = '';
        if (this.searchFilter) filter = this.searchFilter;
        if (this.filter) filter = this.filter;
        if (this.searchFilter && this.filter)
          filter = this.searchFilter + ',' + this.filter;

        // console.log(this.controlForm.value);
        if (termSearchString.length == 0 && this.controlForm.value) {
          filter = this.dbNameOfId + '=' + this.controlForm.value;
        }

        let inputTypeahead: InputTypeahead = {
          endpoint: this.routeEndpoint,
          orderBy: this.orderBy,
          pageSize: this.pageSize,
          domain: this.domain,
          filter: filter,
        };

        //Si on a déjà fait la recherche juste avant. Only avec Id
        if (!(filter.includes(this.dbNameOfId) && filter === this.oldFilter)) {
          this.typeaheadApiService
            .get(inputTypeahead)
            .pipe(take(1))
            .subscribe((result) => {
              this.dataSource$.next(result);

              if (
                this.showFirstElement ||
                (this.isFirstTimeInit && result.length === 1)
              ) {
                this.isFirstTimeInit = false;
                this.onValueMemberChange(result[0]);
              }
            });
        }
        //hack to avoid double load
        this.oldFilter = filter;
      });

    this.quickAddResult$
      .pipe(
        filter((x) => !!x && !!this.routeEndpoint),
        take(1)
      )
      .subscribe((item) => {
        if (item != undefined) {
          // console.log(item.id)
          this.onValueMemberChange({ id: item.id });
          this.searchInput$.next('');
          this.closeSelect(this.ngSelect);
        }
      });

    if (this.routeEndpoint) {
      if (!this.limitToList) {
        this.quickAdd = (term) => {
          this.quickAddFn(term);
        };
      }

      this.controlForm?.valueChanges.subscribe((x) => {
        this.searchInput$.next('');
      });
    }

    if (this.referenceEndpoint) {
      if (this.dataType === DataType.Enum) {
        this.usedMember = this.valueMember;
      }
      if (this.dataType === DataType.ReferenceData) {
        this.valueMember = 'name';
        // }
      }

      if (!this.limitToList) {
        this.quickAdd = (term) => {
          this.addItemsFn(term);
        };
      }
      this.callReferenceData();
    }

    if (this.data && this.dataValueMember) {
      const result = this.data.map(
        (item) => <ReferencesItem>{ name: item[this.dataValueMember] }
      );
      this.dataSource$.next(result);
    }

    // this.data
    //   .pipe(
    //     filter((x) => !!x && !!this.dataValueMember),
    //     take(1),
    //     map((result) => {
    //       return result.map(
    //         (item) => <ReferencesItem>{ name: item[this.dataValueMember] },
    //       );
    //     }),
    //   )
    //   .subscribe((item) => {
    //     if (item != undefined) {
    //       this.dataSource$.next(item);
    //     }
    //   });

    this.isInitEnded = false;
  }

  quickAddFn(term: string) {
    this.typeaheadService.quickAdd.call({
      name: term,
      endpoint: this.routeEndpoint,
    });
  }

  onValueMemberChange(newValue: { id: number | string | undefined }) {
    this.controlForm.setValue(
      newValue ? newValue[this.valueMember] : undefined
    );
    // this.searchInput$.next('');
    //this.closeSelect(this.ngSelect)
  }

  cleanSearchTerm() {
    this.termSearchString = '';
    this.ngSelect.searchTerm = '';
    this.onValueMemberChange({ id: undefined });
    // this.searchInput$.next('');
  }

  openModalDetails() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        this.entity.toString() + 'DetailsContentComponent'
      );
    modalRef.componentInstance.title = this.entity.toString();
    modalRef.componentInstance.inputParams = {
      modalId: this.controlForm.value,
    };
  }

  openModalCreate() {
    this.modalExchangeService.outputFromDynamicComponent('-1');
    this.onValueMemberChange({ id: undefined });

    // const modalRef = this.modalService.open(ModalContentComponent, {size: "lg", backdrop: "static"});
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        this.entity.toString() + 'CreateContentComponent'
      );
    modalRef.componentInstance.title = this.entity.toString();
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower(this.entity.toString()) + 'Create',
      },
    ];
    modalRef.componentInstance.inputParams = {
      modalName: this.termSearchString,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.onValueMemberChange({ id: id });
        }
      })
      .finally(() => this.searchInput$.next(''));
  }

  closeSelect(select: NgSelectComponent) {
    select.close();
  }

  onFocusOut($event: FocusEvent) {
    if (!this.controlForm.value) {
      this.ngSelect.searchTerm = this.termSearchString;
    }
  }

  onFocusIn($event: FocusEvent) {
    if (!this.controlForm.value) {
      this.ngSelect.searchTerm = this.termSearchString;
    }
  }

  //***************//
  // ReferenceData //
  //***************//
  private callReferenceData() {
    switch (this.dataType) {
      case DataType.ReferenceData:
        if (!this.data) {
          const inputReferenceDataTypeahead: InputReferenceDataTypeahead = {
            endpoint: this.referenceEndpoint,
            orderBy: this.orderBy,
            field: this.referenceField,
            filterParentName: this.referenceFilterParentName,
            filterParentValue: this.referenceFilterParentValue,
          };
          this.typeaheadApiService
            .getReferencesItems(inputReferenceDataTypeahead)
            .pipe(take(1))
            .subscribe((result) => this.dataSource$.next(result));
        }

        break;
      case DataType.Enum:
        if (!this.data) {
          const inputEnumDataTypeahead: InputEnumDataTypeahead = {
            endpoint: this.referenceEndpoint,
            enumType: this.referenceField,
          };
          this.typeaheadApiService
            .getEnumItems(inputEnumDataTypeahead)
            .pipe(take(1))
            .subscribe((result) => this.dataSource$.next(result));
        }
        break;
      default:
        break;
    }

    this.dataSource$
      .pipe(
        filterTrue(),
        filter((x) => this.showFirstElement),
        untilDestroyed(this)
        // take(1),
      )
      .subscribe((x) => {
        // console.log(this.referenceFilterParentName);
        // console.log(this.referenceFilterParentValue);
        // console.log(this.isNewValue);
        // console.log(x.length);
        if (this.referenceFilterParentName && this.isNewValue) {
          if (x.length > 0) {
            this.onValueMemberRefChange(x[0]);
          } else {
            this.cleanRefTerm();
          }
        }
      });
  }

  addItemsFn(term: string) {
    if (this.dataType === DataType.Enum) return;

    let itemFound =
      this.ngSelectRef.itemsList.items.filter(
        (x) => x.value.name.toLowerCase() === term.toLowerCase()
      ).length > 0;
    if (itemFound) {
      this.controlForm.setValue(term);
      this.closeSelect(this.ngSelectRef);
      return;
    }

    this.ngSelectRef.itemsList.addItem(<ReferencesItem>{ name: term });
    this.ngSelectRef.itemsList.items.sort((a, b) =>
      a.value[this.displayMember].toUpperCase() >
      b.value[this.displayMember].toUpperCase()
        ? 1
        : a.value[this.displayMember].toUpperCase() ===
          b.value[this.displayMember].toUpperCase()
        ? 0
        : -1
    );
    this.controlForm.setValue(term);
    this.closeSelect(this.ngSelectRef);
  }

  customSearchFn(term: string, item: ReferencesItem) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1;
  }

  onValueMemberRefChange(newValue: any) {
    this.controlForm.setValue(newValue ? newValue[this.usedMember] : undefined);
  }

  cleanRefTerm() {
    this.onValueMemberRefChange({ id: undefined });
    this.termSearchString = '';
    this.ngSelectRef.close();
  }
}
