import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { DataType, DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { Entity, View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ContractService } from '../../../../shared/store/contract/contract.service';
import {
  BankAccountAddModel,
  CarInsuranceContractDisasterDetailFormModel,
  CarInsuranceContractFormModel,
  ContractAddBaseModel,
  ContractCarFormModel,
  ContractHealthFormModel,
  ContractHouseFormModel,
  ContractModel,
  ContractStatus,
  CreateContractEntityModel,
  HealthInsuranceContractDetailFormModel,
  HouseInsuranceContractFormModel,
} from '../../../../shared/store/contract/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { formatDate } from '../../../../shared/helper/datehelper';
import {
  InsuranceCategory,
  InsuredType,
} from '../../../../shared/store/lead/types';
import { take } from 'rxjs/operators';
import { ContactService } from 'src/app/shared/store/contact/contact.service';
import { ContactModel } from 'src/app/shared/store/contact/types';
import { FormService } from '../../../../shared/store/form/form.service';
import { debugLog } from '../../../../shared/pipe/rxjs/operators';
import { BankAccountModel } from '../../../../shared/store/bank-account/types';

@UntilDestroy()
@Component({
  selector: 'app-contract-create',
  templateUrl: './contract-create.component.html',
  styleUrls: ['./contract-create.component.scss'],
})
export class ContractCreateComponent implements OnInit {
  contractForm: FormGroup;
  view$: Observable<View>;
  createContractModel$: Observable<ContractModel>;
  productCategoryId: number | undefined = undefined;
  leadId: string;
  productId: string;
  category: number = 0;
  contact$: Observable<ContactModel>;
  companyDomain: string;
  productLineDomain: string;
  productDomain: string;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly DataType = DataType;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly contractService: ContractService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly contactService: ContactService
  ) {
    this.createContractModel$ = this.contractService.getContractModel.value$;
    this.view$ = this.viewService.getViews.value$;
    this.contact$ = this.contactService.getById.value$;
  }

  ngOnInit() {
    this.productCategoryId = this.route.snapshot.params['productCategoryId'];
    this.leadId = this.route.snapshot.params['leadId'];
    this.productId = this.route.snapshot.params['productId'];

    this.contractService.getByUniqueId.reset();
    this.contractService.getHealthDetail.reset();
    this.contractService.getCarDetail.reset();
    this.contractService.getHouseDetail.reset();
    this.contractService.getContractModel.reset();
    this.contractService.getHealthContractModel.reset();
    this.contractService.getCarContractModel.reset();
    this.contractService.getHouseContractModel.reset();
    this.companyDomain = '';

    this.contractForm = this.formBuilder.group({
      clientId: [undefined, [Validators.required]],
      salesTeam: [undefined],
      salesTeamMember: [undefined],
      category: [''],
      categoryLoc: [''],
      categoryColor: [''],
      productCategoryId: [undefined, [Validators.required]],
      companyId: [undefined, [Validators.required]],
      productLineId: [undefined],
      productId: [undefined, [Validators.required]],
      effectiveDate: ['', [Validators.required]],
      subscriptionDate: ['', [Validators.required]],
      contractManagerId: [undefined],
      leadId: [undefined],
      uniqueId: ['00000000-0000-0000-0000-000000000000'],
    });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@contractCreate', x.breadcrumbCreate);
      });

    const createEntityModel: CreateContractEntityModel = {
      // category: InsuranceCategory[this.category],
      productCategoryId: this.productCategoryId,
      leadId: this.leadId,
      productId: this.productId,
    };
    this.contractService.getContractModel.call(createEntityModel);
    this.createContractModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        this.contractForm.patchValue({
          name: x.name,
          category: this.category,
          categoryLoc: x.categoryLoc,
          categoryColor: x.categoryColor,
          productCategoryId: x.productCategoryId,
          salesTeam: x.salesTeamId,
          salesTeamMember: x.salesPersonId,
          effectiveDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          subscriptionDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          contractManagerId: x.contractManagerId,
          leadId: !!x.leadId ? x.leadId : undefined,
          clientId: !!x.contactId ? x.contactId : undefined,
          uniqueId: x.uniqueId,
        });
      });

    this.contractForm.controls['clientId']?.valueChanges.subscribe((x) => {
      if (x) {
        this.contactService.getById.call(x);
      } else {
        this.contactService.getById.reset();
      }
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        if (x == 'contractCreatePush') this.onSubmit();
        if (x == 'contractList') {
          this.formService.clear();
          this.router.navigate(['/Crm/ContractRequests/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.contractForm.controls['companyId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.productLineDomain =
          value == null ? '' : `insuranceCompanyId=${value}`;
      });
    this.contractForm.controls['productLineId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        debugger;
        this.productDomain = value == null ? '' : `productLineId=${value}`;
      });
    this.contractForm.controls['productId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {});
    // this.contractForm.controls['companyKey'].valueChanges
    //   .pipe(untilDestroyed(this))
    //   .subscribe((value) => {});
  }

  onSubmit(): void {
    if (this.contractForm.invalid) {
      this.contractForm.markAllAsTouched();
      this.formService.countErrors(this.contractForm, true);

      return;
    }

    let contractModel: ContractModel = {
      id: 0,
      categoryLoc: '',
      category: InsuranceCategory.Health,
      salesTeamId: this.contractForm.value.salesTeam,
      salesPersonId: this.contractForm.value.salesTeamMember,
      campaignId:
        this.contractForm.value.marketingFormGroup.marketingCampaignId,
      medium: this.contractForm.value.marketingFormGroup.marketingMedium,
      source: this.contractForm.value.marketingFormGroup.marketingSource,
      monthlyFee:
        this.contractForm.value.generalInformationFormGroup.monthlyFee,
      creationDate: new Date(),
      companyKey: this.contractForm.value.companyKey,
      productLineKey: this.contractForm.value.productLineKey,
      productKey: this.contractForm.value.productKey,
      statusId: ContractStatus.Draft,
      productLineId: this.contractForm.value.productLineId,
      productId: this.contractForm.value.productId,
      paymentSplittingId:
        +this.contractForm.value.paymentSplittingFormGroup.paymentSplittingId,
      startDate: this.contractForm.value.effectiveDate,
      endDate: this.contractForm.value.effectiveDate,
      name: this.contractForm.value.name,
      subscriptionDate: this.contractForm.value.subscriptionDate,
      documentsReceivedDate:
        this.contractForm.value.generalInformationFormGroup
          .documentsReceivedDate,
      regularizationDate:
        this.contractForm.value.generalInformationFormGroup.regularizationDate,
      documentsSentDate:
        this.contractForm.value.generalInformationFormGroup.documentsSentDate,
      // expiryMonth:
      //   this.contractForm.value.generalInformationFormGroup.expiryMonth,
      // expiryDay: this.contractForm.value.generalInformationFormGroup.expiryDay,
      expiryMonth: 1,
      expiryDay: 1,
      terminationDate:
        this.contractForm.value.generalInformationFormGroup.terminationDate,
      terminationReasonId:
        this.contractForm.value.generalInformationFormGroup.terminationId,
      applicationFees:
        this.contractForm.value.generalInformationFormGroup.applicationFees,
      monthlyCost:
        this.contractForm.value.generalInformationFormGroup.monthlyCost,
      commissionRate:
        this.contractForm.value.generalInformationFormGroup.commissionRate,
      taxRate: this.contractForm.value.generalInformationFormGroup.taxRate,
      validationDate:
        this.contractForm.value.generalInformationFormGroup.validationDate,
      approverId:
        this.contractForm.value.generalInformationFormGroup.approverId,
      contractManagerId: this.contractForm.value.contractManagerId,
      contactId: this.contractForm.value.clientId,
      leadId: this.contractForm.value.leadId,
      productCategoryId: this.contractForm.value.productCategoryId!,

      uniqueId: this.contractForm.value.uniqueId!,

      //Others
      productCategoryName: '',
      categoryColor: '',
      productCategoryProductCategoryEnumKey: 0,
      contactGender: '',
      contactFirstName: '',
      contactLastName: '',
      contactPhoneNumber: '',
      contactEmail: '',
      contactZipCode: '',
      contactCity: '',
      contactAddress: '',
      contactAddress2: '',
      contactCountryName: '',
      gender: '',
      statusInt: 0,
      statusLoc: '',
      statusColor: '',
      annualFee: undefined,
      paymentBankAccountId: 0,
      paymentBankAccount: undefined,
      refundingBankAccountId: 0,
      refundingBankAccount: undefined,
      paymentSplittingMode: '',
      paymentSplittingDay: '',
      campaignName: '',
      salesTeamName: '',
      salesPersonContactName: '',
      contactName: '',
      terminationReasonName: '',
      approverContactName: '',
      contractManagerContactName: '',
      documents: [],
      clientAccountMoves: [],
      companyAccountMoves: [],
    };

    let paymentBankAccount: BankAccountModel = {
      bankId:
        this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
          .bankId!,
      contactId: this.contractForm.value.clientId!,
      holderName:
        this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
          .holderName!,
      iban: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
        .iban!,
      bic: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
        .bic!,
      active: true,

      //Others
      id: 0,
      countryId: undefined,
      bankName: '',
      bankBIC: '',
      address: '',
      address2: '',
      zipCode: '',
      city: '',
      fullCity: '',
      countryName: '',
    };
    let refundingBankAccount: BankAccountModel;
    if (this.contractForm.value.paymentSplittingFormGroup.sameBankAccount) {
      refundingBankAccount = {
        bankId:
          this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
            .bankId!,
        contactId: this.contractForm.value.clientId!,
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
            .holderName!,
        iban: this.contractForm.value.paymentSplittingFormGroup
          .paymentBankAccount.iban!,
        bic: this.contractForm.value.paymentSplittingFormGroup
          .paymentBankAccount.bic!,
        active: true,

        //Others
        id: 0,
        countryId: undefined,
        bankName: '',
        bankBIC: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        fullCity: '',
        countryName: '',
      };
    } else {
      refundingBankAccount = {
        bankId:
          this.contractForm.value.paymentSplittingFormGroup.refundingBankAccount
            .bankId!,
        contactId: this.contractForm.value.clientId!,
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.refundingBankAccount
            .holderName!,
        iban: this.contractForm.value.paymentSplittingFormGroup
          .refundingBankAccount.iban!,
        bic: this.contractForm.value.paymentSplittingFormGroup
          .refundingBankAccount.bic!,
        active: true,

        //Others
        id: 0,
        countryId: undefined,
        bankName: '',
        bankBIC: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        fullCity: '',
        countryName: '',
      };
    }

    switch (this.category) {
      case InsuranceCategory.Health: {
        contractModel.category = InsuranceCategory.Health;
        const subscriber: HealthInsuranceContractDetailFormModel = {
          socialSecurityKey:
            this.contractForm.value.healthFormGroup.subscriberRegime,
          birthDate:
            this.contractForm.value.healthFormGroup.subscriberBirthDate,
          insuredType: InsuredType.Holder,
          firstName:
            this.contractForm.value.healthFormGroup.subscriberFirstName,
          lastName: this.contractForm.value.healthFormGroup.subscriberLastName,
          birthName: '',
          affiliation: 'None',
          socialSecurityNumber:
            this.contractForm.value.healthFormGroup
              .subscriberSocialSecurityNumber,
          codeCpam: this.contractForm.value.healthFormGroup.subcriberCodeCpam,
          gender: this.contractForm.value.healthFormGroup.subscriberGender,
          maritalStatus: this.contractForm.value.maritalStatus,
        };

        let partners: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.partners.forEach((x) => {
          const partner: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.partnerRegime,
            birthDate: x.partnerBirthDate,
            insuredType: InsuredType.Partner,
            firstName: x.partnerFirstName,
            lastName: x.partnerLastName,
            maritalStatus: this.contractForm.value.maritalStatus,
            birthName: '',
            gender: x.partnerGender,
            socialSecurityNumber: x.partnerSocialSecurityNumber,
            codeCpam: x.partnerCodeCpam,
            affiliation: 'None',
          };
          partners.push(partner);
        });

        let children: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.children.forEach((x) => {
          const index = x.index;

          let child: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.childRegime,
            birthDate: x.childBirthDate,
            insuredType: InsuredType.Child,
            firstName: x.childFirstName,
            lastName: x.childLastName,
            birthName: '',
            gender: x.childGender,
            maritalStatus: 1,
            socialSecurityNumber: x.childSocialSecurityNumber,
            codeCpam: x.childCodeCpam,
            affiliation: x['childAffiliation' + index],
          };

          if (x['childAffiliation' + index] == 'Subscriber') {
            child.socialSecurityNumber =
              this.contractForm.value.healthFormGroup.subscriberSocialSecurityNumber;
            child.codeCpam =
              this.contractForm.value.healthFormGroup.subcriberCodeCpam;
          }
          if (x['childAffiliation' + index] == 'Partner') {
            child.socialSecurityNumber = partners[0].socialSecurityNumber;
            child.codeCpam = partners[0].codeCpam;
          }
          children.push(child);
        });

        let healthInsuranceContractDetailFormModel: HealthInsuranceContractDetailFormModel[] =
          [subscriber, ...partners, ...children];

        let contractHealthFormModel: ContractHealthFormModel = {
          contractModel: contractModel,
          healthInsuranceContractDetailFormModels:
            healthInsuranceContractDetailFormModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
        };

        let result$ = this.contractService.createHealthContract.call(
          contractHealthFormModel
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1)
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      case InsuranceCategory.Car: {
        contractModel.category = InsuranceCategory.Car;

        let carInsuranceContractFormModel: CarInsuranceContractFormModel = {
          driverFirstName:
            this.contractForm.value.driverFormGroup.driverFirstname,
          driverLastName:
            this.contractForm.value.driverFormGroup.driverLastname,
          driverBirthdate:
            this.contractForm.value.driverFormGroup.driverBirthdate,
          driverZipCode: this.contractForm.value.driverFormGroup.driverZipCode,
          driverCity: this.contractForm.value.driverFormGroup.driverCity,
          driverAddress: this.contractForm.value.driverFormGroup.driverAddress,
          licenseDate:
            this.contractForm.value.driverFormGroup.driverLicenseDate === ''
              ? undefined
              : this.contractForm.value.driverFormGroup.driverLicenseDate,
          isDriverVehiculeRegistrationHolder:
            this.contractForm.value.driverFormGroup
              .driverVehicleRegistrationHolder,
          vehiculeRegistrationHolder:
            this.contractForm.value.vehicleFormGroup.vehicleRegistrationHolder,
          vehiculeRegistrationHolderNameLastName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderCompanyName,
          vehiculeRegistrationHolderNameFirstName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameLastName,
          vehiculeRegistrationHolderCompanyName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameFirstName,
          vehiculeRegistrationHolderBirthdate:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderBirthdate === ''
              ? undefined
              : this.contractForm.value.vehicleFormGroup
                  .vehicleRegistrationHolderBirthdate,
          yearsInsured:
            this.contractForm.value.driverFormGroup.driverYearsInsured,
          bonusMalus: this.contractForm.value.driverFormGroup.driverBonusMalus,
          monthInsuranceInterruption:
            this.contractForm.value.driverFormGroup
              .driverMonthInsuranceInterruption,
          insuranceTermination:
            this.contractForm.value.driverFormGroup.driverInsuranceTermination,
          licenseAnnulation:
            this.contractForm.value.driverFormGroup.driverLicenseAnnulation.toString(),
          noLicenseConviction:
            this.contractForm.value.driverFormGroup.driverNoLicenseConviction,
          licenseCancel:
            this.contractForm.value.driverFormGroup.driverLicenseCancel,
          licenseCancelMotive:
            this.contractForm.value.driverFormGroup.driverLicenseCancelMotive,
          licenseCancelDate:
            this.contractForm.value.driverFormGroup.driverLicenseCancelDate ===
            ''
              ? undefined
              : this.contractForm.value.driverFormGroup.driverLicenseCancelDate,
          licenseSuspension:
            this.contractForm.value.driverFormGroup.driverLicenseSuspension,
          licenseSuspensionMotive:
            this.contractForm.value.driverFormGroup
              .driverLicenseSuspensionMotive,
          licenseSuspensionDate:
            this.contractForm.value.driverFormGroup
              .driverLicenseSuspensionDate === ''
              ? undefined
              : this.contractForm.value.driverFormGroup
                  .driverLicenseSuspensionDate,
          longestSuspension:
            this.contractForm.value.driverFormGroup.driverLongestSuspension,
          alcoholDrugControl:
            this.contractForm.value.driverFormGroup.driverAlcoholDrugControl,
          nbCarDisaster: '',
          licensePlateNumber:
            this.contractForm.value.vehicleFormGroup.vehicleLicensePlateNumber,
          brand: this.contractForm.value.vehicleFormGroup.vehicleBrand,
          model: this.contractForm.value.vehicleFormGroup.vehicleModel,
          exactModel:
            this.contractForm.value.vehicleFormGroup.vehicleExactModel,
          carBody: this.contractForm.value.vehicleFormGroup.vehicleCarBody,
          carEnergie: this.contractForm.value.vehicleFormGroup.vehicleCarEnergy,
          carPower: this.contractForm.value.vehicleFormGroup.vehicleCarPower,
          vehiculeUsage: this.contractForm.value.vehicleFormGroup.vehicleUsage,
          vehiculeUsageProSupp:
            this.contractForm.value.vehicleFormGroup.vehicleUsageProSupp,
          parkingType:
            this.contractForm.value.vehicleFormGroup.vehicleParkingType,
          dateOfCirculation:
            this.contractForm.value.vehicleFormGroup
              .vehicleDateOfCirculation === ''
              ? undefined
              : this.contractForm.value.vehicleFormGroup
                  .vehicleDateOfCirculation,
          contractStartDate:
            this.contractForm.value.effectiveDate === ''
              ? undefined
              : this.contractForm.value.effectiveDate,
          insuranceCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceInsuranceCoverage,
          currentInsuranceCompany:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceCurrentInsuranceCompany,
          driverBodyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverBodyCoverage,
          replacementVehicleCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceReplacementVehicleCoverage,
          driverPropertyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverPropertyCoverage,
          brandNewCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceBrandNewCoverage,
          moneyLossCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceMoneyLossCoverage,
        };

        let disasters: CarInsuranceContractDisasterDetailFormModel[] = [];

        this.contractForm.value.driverFormGroup.driverDisasters.forEach((x) => {
          const disaster: CarInsuranceContractDisasterDetailFormModel = {
            carDisasterDate: x.carDisasterDate,
            carDisasterReason: x.carDisasterReason,
            carDisasterResponsiblePerson: x.carDisasterResponsiblePerson,
          };
          disasters.push(disaster);
        });

        const contractCarFormModel: ContractCarFormModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
          carInsuranceContractFormModel: carInsuranceContractFormModel,
          carInsuranceContractDisasterDetailFormModels: disasters,
        };

        let result$ =
          this.contractService.createCarContract.call(contractCarFormModel);
        result$
          .pipe(
            filter((x) => !!x),
            take(1)
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });

        break;
      }
      case InsuranceCategory.House: {
        contractModel.category = InsuranceCategory.House;
        const houseInsuranceContractModel: HouseInsuranceContractFormModel = {
          address: this.contractForm.value.houseFormGroup.houseAddress,
          zipCode: this.contractForm.value.houseFormGroup.houseZipCode,
          city: this.contractForm.value.houseFormGroup.houseCity,
          maritalStatus: this.contractForm.value.houseFormGroup.houseAddress,
          profession: this.contractForm.value.houseFormGroup.houseAddress,
          housingType: this.contractForm.value.houseFormGroup.houseHousingType,
          occupancyRole:
            this.contractForm.value.houseFormGroup.houseOccupancyRole,
          nbOfRooms: this.contractForm.value.houseFormGroup.houseNbOfRooms,
          area: this.contractForm.value.houseFormGroup.houseArea,
          constructionType:
            this.contractForm.value.houseFormGroup.houseConstructionType,
          roofType: this.contractForm.value.houseFormGroup.houseRoofType,
          amountToInsure:
            this.contractForm.value.houseFormGroup.houseAmountToInsure,
          flatLevel: this.contractForm.value.houseFormGroup.houseFlatLevel,
          residencyType:
            this.contractForm.value.houseFormGroup.houseResidencyType,
          fireCoverage:
            this.contractForm.value.houseFormGroup.houseFireCoverage,
          waterCoverage:
            this.contractForm.value.houseFormGroup.houseWaterCoverage,
          theftCoverage:
            this.contractForm.value.houseFormGroup.houseTheftCoverage,
          glassBreakCoverage:
            this.contractForm.value.houseFormGroup.houseGlassBreakCoverage,
          publicLiabilityCoverage:
            this.contractForm.value.houseFormGroup.housePublicLiabilityCoverage,
          feeCancellationCoverage:
            this.contractForm.value.houseFormGroup.houseFeeCancellationCoverage,
          electricalDamageCoverage:
            this.contractForm.value.houseFormGroup
              .houseElectricalDamageCoverage,
          poolCoverage:
            this.contractForm.value.houseFormGroup.housePoolCoverage,
          dogCoverage: this.contractForm.value.houseFormGroup.houseDogCoverage,
          currentInsuranceCompany:
            this.contractForm.value.houseFormGroup.houseCurrentInsuranceCompany,
        };

        const contractHouseFormModel: ContractHouseFormModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
          houseInsuranceContractFormModel: houseInsuranceContractModel,
        };

        let result$ = this.contractService.createHouseContract.call(
          contractHouseFormModel
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1)
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      case InsuranceCategory.Undefined: {
        contractModel.category = InsuranceCategory.Undefined;

        const contractAddBaseModel: ContractAddBaseModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
        };

        let result$ =
          this.contractService.createContract.call(contractAddBaseModel);
        result$
          .pipe(
            filter((x) => !!x),
            take(1)
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  onCategoryChange(category: number) {
    if (this.category !== category) {
      this.category = category;

      if (this.category && this.leadId) {
        switch (this.category) {
          case InsuranceCategory.Health:
            this.contractService.getHealthContractModel.call(+this.leadId);
            break;
          case InsuranceCategory.Car:
            this.contractService.getCarContractModel.call(+this.leadId);
            break;
          case InsuranceCategory.House:
            this.contractService.getHouseContractModel.call(+this.leadId);
            break;
        }
      }
    }
  }
}
